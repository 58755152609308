import get from 'lodash/get';

const dictionary = {
  100: 'No se encontraron los campos Usuario o Contraseña.',
  101: 'Usuario o e-mail y/o clave incorrectas.',
  102: 'El usuario se encuentra deshabilitado.',
  103: 'Ocurrió un error al intentar conectarse con el servidor. Por favor, comunicate con Administración de AGD.',
  104: 'No tienes los permisos suficientes. Por favor, comunicate con Administración de AGD.',
  105: 'No se encontró un campo obligatorio.',
  106: 'Un campo obligatorio no puede estar vacío.',
  107: 'El campo usuario debe contener entre 4 y 60 caracteres.',
  108: 'El campo contraseña debe contener entre 8 y 256 caracteres.',
  109: 'El campo email debe contener entre 5 y 128 caracteres.',
  110: 'El estado debe contener al menos un número.',
  111: 'El lenguaje debe contener solo 2 caracteres.',
  112: 'Los ID de los roles deben contener entre 1 y 2 números.',
  113: 'El email es inválido.',
  114: 'Este campo solo admite valores numéricos.',
  115: 'La lista de roles debe ser un Array.',
  116: 'El lenguaje solo puede ser en or es.',
  117: 'El estado debe ser 0 para inactivo o 1 para activo.',
  118: 'El CUIT debe tener exactamente 11 números.',
  121: 'El estado debe ser activo o inactivo.',
  125: 'El campo debe ser alfabético.',
  126: 'El nombre y apellido deben tener al menos 2 caracteres.',
  127: 'El nombre y apellido del usuario sólo admiten caracteres alfabéticos y espacios.',
  130: 'El password ingresado no cumple con los requerimientos mínimos.',
  131: 'No se pueden registrar Oficiales de Cuenta duplicados.',
  132: 'No se pueden registrar CUITS duplicados.',
  134: 'No se pueden registrar Roles duplicados.',
  201: 'No cuentas con los permisos para ver la grilla.',
  202: 'Falta un parámetro en la consulta.',
  203: 'El CUIT enviado no corresponde con el usuario.',
  204: 'La Cantidad debe ser un número entre 1 y 99.',
  205: 'Se envió un parámetro con un formato inválido.',
  206: 'Tipo de saldo inválido. Debe ser comercial o contable.',
  207: 'Su código para cambiar la clave ha caducado.',
  208: 'El CUIT ingresado ya fue añadido al usuario.',
  209: 'El campo no es editable.',
  210: 'Los campos desde - hasta no pueden superponerse.',
  211: 'La información del CUIT <cuit_nro> comenzará a mostrarse dentro de las próximas 24hs.',
  215: 'El tipo de archivo es inválido.',
  401: 'El CUIT ingresado no existe.',
  402: 'No se encontró el item de configuración solicitado.',
  403: 'No se encontró el módulo o grilla a modificar.',
  404: 'El CUIT ingresado no se encuentra activo en AGD.',
  405: 'El comprobante no se encuentra disponible. Por favor, intente nuevamente en unos momentos.',
  407: 'El campo \'respuesta\' puede contener hasta 250 caracteres',
  500: 'Error del servidor.',
  501: 'Error de base de datos.',
  502: 'Error de conexión con AFIP.',
  503: 'La descarga de PDFs se encuentra deshabilitada.',
  504: 'Su solicitud no pudo ser procesada correctamente, por favor intente nuevamente más tarde.',
  505: 'Su solicitud no pudo ser procesada correctamente, por favor intente nuevamente más tarde.',
  509: 'El tamaño del archivo es demasiado grande.',
  510: 'Error al intentar obtener posición',
  511: 'Error al intentar obtener entrega',
  600: 'El nombre de usuario y/o email ingresado ya se encuentra registrado.',
  601: 'Valor de ReCaptcha inválido.',
  602: 'El email de validación ya fue enviado a su casilla de correo.',
  603: 'El token de validación expiró! Por favor, realice la solicitud nuevamente.',
  604: 'El email no se encuentra registrado en la base de datos.',
  605: 'El token enviado en el email es inválido.',
  606: 'El token de activación expiró! Por favor, realice la solicitud nuevamente.',
  607: 'El token de activación es inválido.',
  608: 'El email del usuario no ha sido validado todavía.',
  609: 'El email ya había sido validado en el sistema.',
  610: 'El email ya había sido activado en la Plataforma.',
  611: 'El usuario no existe en el sistema.',
  612: 'El nuevo email se encuentra en uso por otro usuario',
  613: 'El teléfono debe comenzar con + y tener entre 9 y 15 caracteres numéricos.',
  614: 'El número de telefonía debe constar de 10 dígitos.',
  615: 'El código ya fue enviado. Por favor, revisa tu celular o espera 3 minutos para solicitar uno nuevo.',
  616: 'El código de validación ya fue verificado.',
  617: 'El código de validación expiró! Por favor, solicite uno nuevo.',
  618: 'El código de validación no es correcto.',
  619: 'El código de validación debe constar de 6 números.',
  620: 'No tienes un número de telefonía móvil registrado.',
  621: 'El token de Firebase ya existe en la base de datos.',
  622: 'El motivo ya se encuentra registrado.',
  700: 'El sistema se encuentra en mantenimiento.',
  800: 'El número de CUIT no esta asociado con AgriPago.',
  801: 'El rol AgriPago no esta asociado con el usuario actual.',
  802: 'Los usuarios de LDAP no estan autorizados a utilizar la aplicación AgriPago.',
  803: 'El CUIT asociado a AgriPago debe pertenecer a un usuario con ese rol.',
  804: 'Debes asociar algún CUIT al rol AgriPago.',
  805: 'Ocurrió un error al crear la consulta, intente nuevamente en unos minutos.',
  806: 'El estado de la consulta es inválido.',
  807: 'La consulta ya fue valorada.',
  808: 'Debes asociar algún CUIT al rol Experta.',
  809: 'El CUIT asociado a Experta debe pertenecer a un usuario con ese rol.',
  810: 'El formato del CUIT no es válido para AgriPago.',
  811: 'El teléfono del usuario debe estar definido.',
  812: 'El usuario de Agripago debe tener Nombre y Apellido definido.',
  813: 'El rol de Venta de Granos debe tener al menos un CUIT asociado.',
  814: 'El CUIT asociado con Venta de Granos debe pertenecer a un usuario con ese rol.',
  815: 'El CUIT asociado con Venta de Granos debe ser un rol Productor.',
  816: 'El CUIT no esta asociado al rol Venta de Granos.',
  819: 'Se encontro un nuevo precio para este producto/entrega/moneda.',
  820: 'La Cosecha seleccionada para crear la Orden no es válida o no existe.',
  821: 'Saldo granario insuficiente para realizar la orden.',
};

/**
 * Gets error message based on error code.
 * @param errorCode
 */
export function lookUp(errorCode: string) {
  const error = dictionary[errorCode];

  if (error) {
    return error;
  }

  return `Se produjo un error desconocido. [${errorCode}]`;
}

/**
 * Gets error code.
 * @param err
 */
export function getErrorCode(err: any) {
  return get(err, 'error.errors[0].msg.errorCode', false) || get(JSON.parse(err.error), 'errors[0].msg.errorCode', false);
}

// if errorCode is defined and is not at excluded error codes, we present error toast
export const silentErrorCodes = [
  '103',
  '104',
  '105',
  '106',
  '204',
  '404',
  '504',
  '505',
  '507',
  '614',
  '615',
  '616',
  '620',
  '621',
  '622',
  '700',
  '816',
  '819',
  '821',
];
